@import "../../../assets/styles/variables";

.table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid rgba(26, 27, 28, 0.125);


  tr {
    border-bottom: 1px solid rgba(26, 27, 28, 0.125);
    background-color: #fff;
    th,
    td {
      vertical-align: middle;
      padding: 6px 8px;
    }
    th{
      font-weight: 600;
    }
  }

  &--fixed {
    table-layout: fixed;
  }

  &--striped {
    tr:nth-child(even) {
      background-color: #f2f2f2;
    }
  }

  &--noBorder {
    border: none;

    tr {
      border: none;
    }
  }

  &--large {
    tr {
      th,
      td {
        padding: 10px 12px;
      }
    }
  }
}
