@import "../../../assets/styles/variables";

.breadcrumbs {
  padding: 0;
  margin: 0;
  user-select: none;
  display: flex;

  li {
    display: flex;
    align-items: center;

    a {
      transition: color .15s ease-in-out, background-color .15s ease-in-out;
      color: $gray-600;
      display: block;
      white-space: nowrap;
      overflow: hidden;
    }

    &:not(:last-child)::after {
      content: '/';
      color: $gray-600;
      text-align: center;
      margin: 0 8px;
    }

    &:last-child {
      pointer-events: none;
      cursor: default;

      a {
        color: $black;
      }
    }
  }
}
