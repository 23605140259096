@import "../../assets/styles/variables";

.notice {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 99999;
  width: 350px;
}


