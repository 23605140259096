@import "../../../assets/styles/variables";

.table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid rgba(26, 27, 28, 0.125);

  &-border{
    border: 1px solid $gray-100
  }

  tr {
    border-bottom: 1px solid rgba(26, 27, 28, 0.125);
    background-color: #fff;

    th,
    td {
      vertical-align: middle;
      padding: 6px 8px;
    }

    th {
      font-weight: 600;
    }

    th.table-th--sortable {
      cursor: pointer;

      &:hover {
        background-color: #f2f2f2;
      }
    }
  }

  tbody {
    tr {
      &:nth-child(even) {
        background-color: #f2f2f2;
      }
    }
  }
}
