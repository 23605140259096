@import "variables";

//Buttons
$btn-font-weight: 600 !default;
$btn-box-shadow: none !default;

//Dropdowns
$dropdown-header-color:  $dark !default;

//Inputs
$input-box-shadow: none !default;
$input-focus-box-shadow: none !default;
$input-btn-focus-box-shadow: none !default;
$input-btn-focus-width: 0 !default;

