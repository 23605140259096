@import "../../../assets/styles/variables";

.avatar {
  border-radius: 100%;
  height: 40px;
  width: 40px;
  border: 1px solid $gray-300;
  background-color: $gray-100;
  color: $gray-500;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    border-radius: 100%;
    width: 40px;
    height: 40px;
  }

  &-alt {
    color: $gray-500;
    font-size: 0.875rem;
  }
}
