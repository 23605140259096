.react-datepicker-wrapper{
  width: 100%;
  display: block;
}


.react-datepicker__day, .react-datepicker__day-name, .react-datepicker__time-name{
  margin: .080rem;
}

.react-datepicker__month{
  margin: 0;
}