// Color system

$white: #fff !default;
$yellow: #FFDB4D !default;
$green: #29A745 !default;
$red: #DC3545 !default;
$blue: #00A0E3 !default;
$orange: #FA6C00 !default;
$gray-100: #e6e6e6 !default;
$gray-200: #D9D9D9 !default;
$gray-300: #cccccc !default;
$gray-400: #BFBFBF !default;
$gray-500: #808080 !default;
$gray-600: #666666 !default;
$gray-700: #4D4D4D !default;
$gray-800: #333333 !default;
$gray-900: #1A1A1A !default;
$black: #1A1B1C !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
                (
                        "100": $gray-100,
                        "200": $gray-200,
                        "300": $gray-300,
                        "400": $gray-400,
                        "500": $gray-500,
                        "600": $gray-600,
                        "700": $gray-700,
                        "800": $gray-800,
                        "900": $gray-900
                ),
                $grays
);

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
                (
                        "white": $white,
                        "yellow": $yellow,
                        "grey": $gray-400,
                        "green": $green,
                        "red": $red,
                        "blue": $blue,
                        "orange": $orange,
                        "gray": $gray-600,
                        "gray-dark": $gray-800
                ),
                $colors
);

$primary: $yellow !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $blue !default;
$warning: $orange !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
                (
                        "primary": $primary,
                        "secondary": $secondary,
                        "success": $success,
                        "info": $info,
                        "warning": $warning,
                        "danger": $danger,
                        "light": $light,
                        "dark": $dark
                ),
                $theme-colors
);

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 200 !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.
// stylelint-disable value-keyword-case
$font-family-sans-serif: 'Nunito', sans-serif;


// Components
//
// Define common padding and border radius sizes and more.
$border-radius: 0.125rem;

// Body
//
// Settings for the `<body>` element.

$body-color: $black !default;
$font-size-base: 0.875rem !default;

$transition-base: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, color 0.15s ease-in-out, background-color 0.15s ease-in-out;

.white {
  color: $white;
}

.yellow {
  color: $yellow;
}

.green {
  color: $green;
}

.red {
  color: $red;
}

.blue {
  color: $blue;
}

.orange {
  color: $orange;
}

.gray-100 {
  color: $gray-100;
}

.gray-200 {
  color: $gray-200;
}

.gray-300 {
  color: $gray-300;
}

.gray-400 {
  color: $gray-400;
}

.gray-500 {
  color: $gray-500;
}

.gray-600 {
  color: $gray-600;
}

.gray-700 {
  color: $gray-700;
}

.gray-800 {
  color: $gray-800;
}

.gray-900 {
  color: $gray-900;
}

$component-active-color: $white !default;
$component-active-bg: $info !default;
$link-color:  $blue !default;
