@import "../../assets/styles/variables";

.label {
  color: $gray-500;
  font-weight: 400;
}

.value {
  font-size: 1.25rem;
  font-weight: 600;
}