@import "../../../assets/styles/variables";

.menu {
  width: 100%;
  user-select: none;
  padding: 0;
  margin: 0;

  &-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: $transition-base;
    color: $gray-400;
    padding: 0 16px 0 56px;
    height: 48px;
    position: relative;

    &__icon {
      position: absolute;
      top: 50%;
      left: 16px;
      height: 24px;
      width: 24px;
      transform: translateY(-50%);
    }

    &::before {
      content: '';
      height: 100%;
      width: 4px;
      border-radius: $border-radius;
      position: absolute;
      background-color: transparent;
      top: 0;
      left: 0;
      transition: $transition-base;
    }

    &:hover {
      text-decoration: none;
      color: $white;
      background-color: $gray-800;
    }

    &--active {
      color: $yellow;

      &::before {
        background-color: $yellow;
        transition: $transition-base;
      }

      &:hover {
        color: $yellow;
        background-color: transparent;
      }
    }
  }

  &-submenu {
    margin: 0;
    padding: 0;
    display: none;

    .menu-item {
      height: 36px;
      &::before {
        content: none
      }
    }
    &--open{
      display: block;
    }
  }
}
