.text-bigger {
  font-size: .9375rem;
}

.row {
  &-cell {
    // фиксированные размеры столбцов, чтобы разные таблицы в одной вкладке сохраняли структуру
    &--small {
      width: 200px;
    }

    &--medium {
      width: 300px;
    }
  }
}

// своя логика полосатых строк из-за того, что добавленные строки с названиями смен нарушают порядок логики <Table/>
.striped {
  & + & {
    background-color: #f2f2f2;
  }
}
