@import "../../assets/styles/variables";

.profile {

  &-img {
    margin-bottom: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    width: 48px;
    height: 48px;
    border-radius: 100%;
    overflow: hidden;
    border: 1px solid $gray-700;
    color: $gray-500;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-name {
    margin-bottom: 2px;
    font-weight: 700;
    color:  $gray-400;
  }

  &-position {
    color:  $gray-500;
  }

  a{
    color: $gray-500;
    text-decoration: none;
    &:hover{
      color: $gray-500;
      text-decoration: none;
    }
  }
}

