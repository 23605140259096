@import "src/assets/styles/variables";

.tile {
  font-weight: 700;
  padding: 4px 8px;
  text-transform: uppercase;
  text-align: center;

  &__label {
    font-size: 0.71rem;
  }

  &__value {
    font-size: 2rem;
    line-height: 1;
  }

  &--primary {
    background: $primary;
  }

  &--secondary {
    color: #FFFFFF;
    background: $secondary;
  }

  &--success {
    color: #FFFFFF;
    background: $success;
  }

  &--danger {
    color: #FFFFFF;
    background: $danger;
  }

  &--warning {
    color: #FFFFFF;
    background: $warning;
  }

  &--info {
    color: #FFFFFF;
    background: $info;
  }

  &--light{
    background: $light;
  }

  &--dark {
    background: $dark;
  }
}