@import "../../assets/styles/variables";

.sidebar {
  padding: 20px 0;
  position: fixed;
  background-color: #262521;
  left: 0;
  top: 0;
  width: 240px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  transition: width .15s linear;
  z-index: 1;

  &-toggle {
    border-radius: 2px;
    position: absolute;
    cursor: pointer;
    z-index: 2;
    right: 4px;
    left: auto;
    top: 4px;
    color: $gray-500;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    &--reverse{
      right: auto;
      left: 4px;
      transform: rotate(180deg);
    }
    &:hover{
      transition: background-color .15s linear, color .15s linear;
      color: $white;
    }
  }
}

