@import "../../../assets/styles/variables";

$height: calc(1.5em + 0.75rem + 2px);

.select {
  text-align: left;
  position: relative;

  &__control {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    cursor: pointer;
    background-color: #fff;
    border-radius: $border-radius;
    border: 1px solid $gray-400;
    min-height: $height;
    transition: $transition-base;

    &--opened {
      border-color: lighten($component-active-bg, 25%);

      &:hover {
        border-color: lighten($component-active-bg, .25);
      }
    }

    &--error {
      border-color: $red;

      &:hover {
        border-color: $red;
      }
    }

    &--focused {
      border-color: lighten($component-active-bg, 25%);

      &:hover {
        border-color: lighten($component-active-bg, 25%);
      }
    }


    &--disabled {
      background-color: $gray-200;
      border-color: $gray-400;
    }
  }

  &__placeholder {
    margin-left: 2px;
    margin-right: 2px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: $gray-700;
  }

  &__singleValue {
    color: $gray-700;
  }

  &__indicatorsContainer {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-shrink: 0;

  }

  &__indicatorSeparator {
    display: none;
  }

  &__clearIndicator {
    height: 25px;
    display: flex;
    align-items: center;
    color: $gray-500;
    cursor: pointer;
    user-select: none;

    &:hover {
      color: $gray-700;
    }
  }

  &__valueContainer {
    align-items: center;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    padding: 0 8px;
    -webkit-overflow-scrolling: touch;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
  }

  &__multiValue {
    background-color: #E0E0E0;
    border-radius: $border-radius;
    display: flex;
    min-width: 0;
    margin:  4px 4px 4px 0;
    padding: 0 4px 0 8px;
    height: 25px;
  }

  &__multiValueLabel {
    border-radius: $border-radius;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center;
    margin-right: 4px;
  }

  &__multiValueRemove {
    align-items: center;
    display: flex;
    padding-left: 4px;
    padding-right: 4px;
    color: $gray-700;
    cursor: pointer;
    transition: $transition-base;

    &:hover {
      background-color: transparent;
      color: $gray-700
    }
  }

  &__dropdownIndicator {
    display: flex;
    cursor: pointer;
    padding-left: 4px;
    padding-right: 4px;
    user-select: none;

    &--opened {
      transform: rotate(180deg);
    }

    &--disabled {
      color: $gray-600;
    }

    &--error {
      color: $red;
    }
  }

  &__noOptionsMessage {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__menu {
    margin-top: 1px;
    background-color: #fff;
    overflow: hidden;
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 999;
    border-radius: $border-radius;
    border: 1px solid $gray-400;
  }

  &__menuList {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    position: relative;
    height: 100%;
  }

  &__option {
    cursor: pointer;
    color: $gray-700;
    padding: 0 8px;
    line-height: 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: $border-radius;
    height: 40px;
    transition: $transition-base;

    &:hover {
      background-color: #f2f2f2;
      color: $gray-700;
    }

    &--selected {
      background-color: #f2f2f2;
      color: $gray-700;
    }
  }

  &__group {
  }

  &__groupHeading {
    font-size: 1.143rem;
    line-height: 1.714rem;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 20px;
    margin-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
  }
}
