@import "./variables";

body {
  min-width: 1200px;
}

.required {
  color: $red;
}

.semi-bold {
  font-weight: 600;
}

.pointer {
  cursor: pointer
}

.v-a-middle {
  vertical-align: middle;
}

.map {
  width: 100%;
  height: 500px
}

a:hover {
  text-decoration: none;
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

.borderNone{
  border-bottom: none;
}

.text-root-size {
  font-size: 1rem;
}
