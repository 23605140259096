@import "../../assets/styles/variables";


.table {

  width: 100%;
  border-spacing: 0;

  &__item {
    cursor: pointer;
    background-color: #fff;

    &--redistributed {
      background-color: #FFF3CD;

      &-icon {
        color: $red;
        // чтобы "!" не переносилось на другую строку
        white-space: nowrap;

        i:first-child {
          position: relative;
          top: 2px;
          width: 24px;
          height: 24px;
        }

        i:last-child {
          position: relative;
          top: 4px;
          right: 5px;
          font-size: 0.86rem;
        }
      }
    }

    &__td {
      padding: 6px 8px;
      //width: 15%;

      border-top: 1px solid $gray-200;
      border-bottom: 1px solid $gray-200;

      &:first-child {
        border-left: solid 1px $gray-200;
      }

      &:last-child {
        border-right: 1px solid $gray-200;
      }

      &__payed {
        i {
          position: relative;
          top: 4px;
          font-size: 0.75rem;
          font-weight: 100;
        }

        span {
          position: relative;
          top: -2px;
          right: 8px;
          font-size: 0.75rem;
          font-weight: 700;
          text-transform: uppercase;
        }
      }

      &__timer {
        font-weight: 700;
        font-size: 1.5rem;
        color: $gray-400;

        &--red {
          color: $red;
        }
      }
    }

    &--new {
      background-color: #FFF3CD;
    }

    &:hover {
      background-color: #f2f2f2;
    }


  }

  &__item--active {
    background-color: #f2f2f2;
    cursor: auto;
  }

  &__item_more {

    &__td {
      border: solid 1px $gray-200;
      border-top: none;
      position: relative;

      &__container {
        padding: 1.25rem 1.25rem 2.75rem;
        background-color: #fff;
      }

      &__toggle {
        visibility: visible;
        opacity: 1;
        cursor: pointer;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        border-bottom: 24px solid #ccc;
        border-left: 24px solid transparent;
        border-right: 24px solid transparent;
        height: 0;
        width: 160px;
        text-align: center;

        &::before {
          content: '';
          position: absolute;
          left: 50%;
          top: 1px;
          transform: translateX(-50%);
          border-bottom: 23px solid #fff;
          border-left: 23px solid transparent;
          border-right: 23px solid transparent;
          height: 0;
          width: 158px;
          z-index: 2;
        }

        &-icon {
          position: relative;
          z-index: 3;
        }

        &:hover {

        }
      }

    }


  }


}
