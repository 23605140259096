.content {
  position: relative;
  padding: 16px;
  min-height: 100vh;
  transition: margin-left .15s linear;
  margin-left: 240px;
  z-index: 2;
  background-color: #F5F3ED;

  &--full{
    margin-left: 56px;
  }
}
