@import "../../assets/styles/variables";

.login {
  background-color: #F5F3ED;
  &-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    max-width: 640px;
    width: 100%;
    padding: 40px 0;
    margin: auto;

    &__logo {
      text-align: center;
    }

    &__form {
      padding: 40px 140px;
    }

    &__support {
      font-size: 1.143rem;
      text-align: center;
    }
  }
}
