@import "../../assets/styles/variables";

.notifications {
  position: relative;
  &__count{
    position: absolute;
    background-color: $red;
    padding: 0 8px;
    color: #fff;
    top: -12px;
    right: -12px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 24px;
    height: 24px;
  }
}


